/* SliderComponent.css */

.slider-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Altura del contenedor */
  }
  
  .two-columns-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%; /* Ajusta el ancho del contenedor */
  }
  
  .left-column {
    flex: 1;
    padding: 20px;
  }
  
  .right-column {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .right-column img {
    max-width: 100%;
    height: auto;
  }
  
  .animated-text {
    font-size: 2em; /* Ajusta el tamaño del texto */
    margin-bottom: 20px;
  }
  
  .button-container {
    margin-top: 20px;
  }
  
  .slider-button {
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
  }
  