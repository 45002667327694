.titleCategory {
  text-align: "center";
  font-size: 2em;
  font-weight: bold;
  padding-bottom:80px;

}

.product-item {
  text-align: center;
  /* Centra el contenido horizontalmente */
}

.card-img-top {
  display: block;
  /* Hace que la imagen sea un bloque */
  margin: 0 auto;
  /* Centra horizontalmente la imagen */
}

.slider-container {
  padding-top: 100px;
  text-align: center;
}

.slider-container .slick-prev,
.slider-container .slick-next {
  top: 50%;
  /* Alinea los botones al centro verticalmente */
  transform: translateY(-50%);
  /* Centra los botones verticalmente */
}

.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  transition: transform 0.3s ease;
}

.card-img-top:hover {
  transform: scale(1.05);
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
}

/* Clase personalizada para redondear la tarjeta completa */
.rounded-card {
  border-radius: 15px; /* Ajusta el valor según lo redondeado que desees el contorno */
  overflow: hidden; /* Para asegurarte de que los elementos dentro de la tarjeta no sobresalgan */
}

/* Redondear las esquinas superiores de la imagen de la tarjeta */
.rounded-top {
  border-radius: 15px;
}


.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  width: 230px;
  height: 230px;
  margin: 0 auto;
}

.card-img-top.rounded-circle {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.container-sty {
  width: 100%; 
  height: 200px; 
  background-color: #C6AEA2; 
  margin: 30px 0px; 
  display: inline-grid;
  margin-top: 16px;
  font-weight: 'bold';
}

.column1{
  flex: 1; 
  text-align:center;
  margin: auto;
  letter-spacing:0.05em ;
}

.txt-off{
  font-size:22px;
  color: white;
  font-weight: bold;
}

.column2{
  flex: 1;
  margin: 20px 0px;
  text-align: center;
  
}


/* Media query para pantallas grandes */
@media (min-width: 576px) {
  .titleCategory {
    padding-top: 0px;
    padding-bottom:100px;

  }

  .slider-container {
    padding-top: 0px;
  }

  
}

@media (min-width: 768px) {
  .titleCategory {
    padding-top: 0px;
    padding-bottom:100px;

  }

  .slider-container {
    padding-top: 0px;
  }
}

@media (min-width: 992px) {
  .titleCategory {
    
    padding-bottom:100px;

  }

  .slider-container {
    padding-top: 20px;
  }
  .container-sty {
    display: flex;
    height: 100px;
  }

  .column2{
    text-align: left;
  }

  .txt-off{
    font-size:26px;
  }
}
