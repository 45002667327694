/* styles.css */

.two-columns-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding-top: 50px;
}

.column {
  width: 100%;
  max-width: 400px; /* Máximo ancho de cada columna */
  margin-bottom: 20px;
}

.column > * {
  margin-bottom: 20px; /* Espacio entre los elementos dentro de la columna */
}

@media (min-width: 576px) {
  .column{
    max-width:none;
    width: calc(100% - 20px); /* Ancho de la columna en dispositivos medianos y grandes */

  }
  .two-columns-container {
    justify-content: none;

  }
}

@media (min-width: 768px) {
  .column{
    max-width:none;
    width: calc(100% - 20px); /* Ancho de la columna en dispositivos medianos y grandes */

  }
  .two-columns-container {
    justify-content: none;

  }
}

@media (min-width: 992px) {
  .column {
    width: calc(50% - 20px); /* Ancho de la columna en dispositivos medianos y grandes */
    max-width: none; /* Eliminamos el máximo ancho para permitir que ocupe el espacio disponible */
  }
}
