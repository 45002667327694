.email-sty{
  padding: 5px;
  color:#8F695D;
  border: none;
  width: 170px;
  height: 51px;
  font-size:16px;
}

.submit-sty{
  padding: 5px 10px;
  background-color:#F7DACA;
  border: none;
  color:#8F695D;
  width: 100px;
  height: 61px;
  font-size:16px;
}



@media (min-width: 992px) {
  .email-sty{
    width: 275px;
  }
  
  .submit-sty{
    width: 275px;
 }
}