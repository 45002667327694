/* Estilos base para el modal */
.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  border: none;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  text-align: center;
  font-size: 18px;
  color: #555;
}

.modal-content {
  width: 100%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modal-header h2 {
  font-size: 22px;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #888;
}

.modal-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.modal-image-container {
  width: 40%;
  text-align: center;
}

.modal-image {
  width: 100%;
  height: 45vh;
  max-width: 100%;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.modal-options-container {
  width: 60%;
  padding-left: 20px;
}

.talla-list {
  list-style: none;
  padding: 0;
}

.talla-list label {
  display: block;
  margin-bottom: 8px;
  cursor: pointer;
}

.modal-buttons {
  margin-top: 20px;
  text-align: center;
}

.ip-add-cart-inr {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.ip-add-cart-inr:hover {
  background-color: #0056b3;
}

/* Media queries para dispositivos móviles */
@media screen and (max-width: 600px) {
  .Modal {
      max-width: 90%;
      max-height: 90%;
  }

  .modal-body {
      flex-direction: column;
      align-items: center;
  }

  .modal-image-container {
      width: 100%;
      margin-bottom: 20px;
  }

  .modal-options-container {
      width: 100%;
      padding-left: 0;
  }
}
