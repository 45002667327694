.subcategory-list {
    display: flex;
    flex-wrap: wrap;
  }
  
  .subcategory-item {
    flex: 0 0 calc(33.333% - 20px); /* Ajusta el ancho según tus necesidades */
    margin: 10px;
    text-align: center;
  }
  
  .subcategory-thumbnail {
    width: 100%;
    max-width: 150px; /* Ajusta el tamaño de la miniatura según tus necesidades */
  }
  
  .subcategory-name {
    margin-top: 5px;
    font-size: 14px;
  }
  