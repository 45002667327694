/* Estilos para el BannerComponent */

.elementor-column {
  position: relative;
  overflow: hidden;
}

.animated-text-din{
  font-size:20px
}

.banner-image {
  position: relative;
}

.image-wrapper {
  display: block;
  position: relative;
}

.image-wrapper img {
  display: block;
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.banner-content {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}

.banner-content-inner {
  padding: 10px;
  border-radius: 10px;
}

.banner-content h3 {
  color: #044313;
  font-size: 16px;
  margin: 0;
}

.banner-content h5 {
  margin: 0;
  font-size: 16px;
}

.btn {
  display: inline-block;
  padding: 5px 13px;

  color: white;
  background-color: #ff7f50;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
  font-size: 13px;
}

.btn:hover {
  background-color: #ff6347;
}

.link-bottom {
  margin-top: 10px;
}

.widget-banner.updow:hover .image-wrapper img {
  transform: scale(1.1);
  /* Agrandar la imagen al pasar el ratón */
}

/* Media query para pantallas grandes */
@media (min-width: 576px) {
  .banner-content {
    left: 25%;
  }

  .banner-content h3 {
    font-size: 30px;
    margin: 0;
  }

  .banner-content h5 {
    font-size: 28px;
    margin: 0;
  }
}

@media (min-width: 768px) {
  .btn {
    padding: 10px 20px;
    font-size: 30px;
  }

  .banner-content h3 {
    font-size: 30px;
    margin: 0;
  }

  .banner-content h5 {
    font-size: 28px;
    margin: 0;
  }
}

@media (min-width: 992px) {
  .banner-content {
    left: 33%;
  }

  .btn {
    padding: 10px 20px;
    font-size: 18px;
  }

  .banner-content h3 {
    font-size: 35px;
    margin: 0;
  }

  .banner-content h5 {
    font-size: 30px;
    margin: 0;
  }
  .animated-text-din{
    font-size:40px
  }
}