/* styles.css */

/* Base styles for mobile-first approach */
.cart-container-det {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 auto;
    max-width: 1200px;
    padding: 20px;
  }
  
  .product-column,
  .button-column {
    flex: none;
  }
  
  /* Media query for larger screens (min-width: 576px) */
  @media (min-width: 576px) {
    .cart-container-det {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  
  /* Media query for even larger screens (min-width: 768px) */
  @media (min-width: 768px) {
    .cart-container-det {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  
  /* Media query for large screens (min-width: 992px) */
  @media (min-width: 992px) {
    .product-column {
      flex: 1;
    }
  
    .button-column {
      flex: 1;
    }
  }
  
  /* Additional styles for the rest of the elements */
  .product-item-cart {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom:20px;
  }
  
  .product-thumbnail {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .product-details {
    flex: 1;
  }
  
  .remove-button {
    background-color: red;
    color: white;
    font-size: 1.2rem;
    border: none;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .empty-cart-message {
    text-align: center;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
  }
  
  .order-summary {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    text-align: center;
  }
  
  .order-summary h2 {
    margin-bottom: 10px;
  }
  
  .order-summary hr {
    margin: 10px 0;
  }
  
  .order-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .order-info-item {
    display: flex;
    justify-content: space-between;
    font-size: 25px;
  }
  
  .order-summary-note {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #666;
  }
  
  .MuiFab-extended {
    background-color: #51AC4F;
    color: white;
    margin-top: 20px;
    width: 100%;
  }
  