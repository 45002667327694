/* components/floatingButton/FloatingButton.css */
.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease;
  }
  
  .hidden {
    opacity: 0;
    pointer-events: none;
  }
  
  .visible {
    opacity: 1;
    pointer-events: all;
  }
  