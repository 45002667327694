/* styles.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slider-container {
    width: 98%;
    margin: auto;
    position: relative;
}

.slider-slide {
    display: grid;
    /* Dos columnas */
    background-color: rgba(0, 0, 0, 0.5);
    /* Fondo semi-transparente */
    border-radius: 10px;
    overflow: hidden;
}

.slider-slide .slide-content {
    color: white;
    z-index: 2;
}

.slider-slide .slider-image {
    display: flex;
    justify-content: flex-end;
}

.slider-slide .slider-image img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}

.animated-text {
    animation: slideIn 1s ease-in-out;
}

.button-container {
    margin-top: 20px;
}

.slider-button {
    background-color: #4CAF50;
    /* Color del botón */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
}

.two-columns-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Dos columnas con la misma anchura */
    /* Espacio entre columnas */
}


.left-column,
.right-column {
    padding: 20px;
}

.left-column {
    grid-column: 1 / 2;
    background: none !important;
    /* Primera columna */
}

.right-column {
    grid-column: 2 / 3;
    background: none !important;
    /* Segunda columna */
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}